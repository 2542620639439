import { useState } from "react";
import {
    PrimaryAlertsBlock,
    PrimaryAlertsBlockProps,
} from "./PrimaryAlertsBlock";
import {
    SecondaryAlertsBlock,
    SecondaryAlertsBlockProps,
} from "./SecondaryAlertsBlock";
import { TabItem, TabList } from "../../../../../bidding/common/tab-list";
import { TabContent } from "../../../../../amrPipeline/common/tabs/TabContent";
import { PortfolioUserConfigFilter } from "../../../../../../types/user-config/UserConfigFilter";
import { PortfolioFiltersAlertsTable } from "./PortfolioFilterAlertsTable";
import { AlertOption } from "../../../../../../types/email-preferences/EmailPreferences";
import { useAppSelector } from "../../../../../../effects/useAppSelector";

interface Props extends PrimaryAlertsBlockProps, SecondaryAlertsBlockProps {
    portfolioFilters?: PortfolioUserConfigFilter[];
    isFilterTableDisabled?: boolean;
}

const convertToCount = (option: AlertOption) =>
    option === AlertOption.Never ? 0 : 1;

const renderCounter = (optionsCount: number[]) => {
    const selectedOptions = optionsCount.reduce(
        (acc, option) => acc + option,
        0
    );

    return (
        <span className="text-regular text-warm-grey">
            ({selectedOptions}/{optionsCount.length})
        </span>
    );
};

export function PortfolioAlertsTabs({
    bwicAlert,
    bwicState,
    onChangeBwicAlert,
    inventoryAlert,
    inventoryState,
    onChangeInventoryAlert,
    imAlert,
    imState,
    onChangeImAlert,
    outOfRiAlert,
    outOfRiState,
    onChangeOutOfRiAlert,
    outOfNcAlert,
    outOfNcState,
    onChangeOutOfNcAlert,
    rollerDeadlineAlert,
    rollerDeadlineState,
    onChangeRollerDeadlineAlert,
    portfolioFilters,
    isFilterTableDisabled,
}: Props) {
    const filtersAlertState = useAppSelector(
        (s) => s.manageEmailPreferences.portfolioSavedFilterAlerts
    );
    const [isPrimary, setIsPrimary] = useState(true);

    const primaryAlerts = [
        imAlert,
        outOfRiAlert,
        outOfNcAlert,
        rollerDeadlineAlert,
    ].map(convertToCount);

    const secondaryAlerts = [
        bwicAlert,
        inventoryAlert,
        ...(portfolioFilters
            ? [
                  portfolioFilters.some((p) => {
                      const alert =
                          filtersAlertState[p.referenceName]?.bwicAlert;
                      return alert && alert !== AlertOption.Never;
                  })
                      ? AlertOption.Instant
                      : AlertOption.Never,

                  portfolioFilters.some((p) => {
                      const alert =
                          filtersAlertState[p.referenceName]?.inventoryAlert;
                      return alert && alert !== AlertOption.Never;
                  })
                      ? AlertOption.Instant
                      : AlertOption.Never,
              ]
            : []),
    ].map(convertToCount);

    return (
        <>
            <TabList>
                <TabItem
                    active={isPrimary}
                    title={<>Primary {renderCounter(primaryAlerts)}</>}
                    onClick={() => setIsPrimary(true)}
                />
                <TabItem
                    active={!isPrimary}
                    title={<>Secondary {renderCounter(secondaryAlerts)}</>}
                    onClick={() => setIsPrimary(false)}
                />
            </TabList>
            <TabContent>
                {isPrimary ? (
                    <PrimaryAlertsBlock
                        imAlert={imAlert}
                        imState={imState}
                        onChangeImAlert={onChangeImAlert}
                        outOfRiAlert={outOfRiAlert}
                        outOfRiState={outOfRiState}
                        onChangeOutOfRiAlert={onChangeOutOfRiAlert}
                        outOfNcAlert={outOfNcAlert}
                        outOfNcState={outOfNcState}
                        onChangeOutOfNcAlert={onChangeOutOfNcAlert}
                        rollerDeadlineAlert={rollerDeadlineAlert}
                        rollerDeadlineState={rollerDeadlineState}
                        onChangeRollerDeadlineAlert={
                            onChangeRollerDeadlineAlert
                        }
                    />
                ) : (
                    <>
                        <SecondaryAlertsBlock
                            bwicAlert={bwicAlert}
                            bwicState={bwicState}
                            onChangeBwicAlert={onChangeBwicAlert}
                            inventoryAlert={inventoryAlert}
                            inventoryState={inventoryState}
                            onChangeInventoryAlert={onChangeInventoryAlert}
                        />
                        {!!portfolioFilters && (
                            <PortfolioFiltersAlertsTable
                                disabled={isFilterTableDisabled}
                                savedFilters={portfolioFilters}
                            />
                        )}
                    </>
                )}
            </TabContent>
        </>
    );
}

