import { textLabels } from "../../../../../../constants/text-labels";
import {
    AlertOption,
    instantAlertOptions,
} from "../../../../../../types/email-preferences/EmailPreferences";
import { AlertEnabledState } from "../../../../../../types/enums/AlertEnabledState";
import { EmailPreferencesRadioGroup } from "../EmailPreferencesRadioGroup";

type OnChangeHandler = (alertOption: AlertOption) => void;

export interface SecondaryAlertsBlockProps {
    bwicAlert: AlertOption;
    bwicState?: AlertEnabledState;
    onChangeBwicAlert: OnChangeHandler;
    inventoryAlert: AlertOption;
    inventoryState?: AlertEnabledState;
    onChangeInventoryAlert: OnChangeHandler;
}

export function SecondaryAlertsBlock({
    bwicAlert,
    bwicState,
    onChangeBwicAlert,
    inventoryAlert,
    inventoryState,
    onChangeInventoryAlert,
}: SecondaryAlertsBlockProps) {
    return (
        <>
            <EmailPreferencesRadioGroup
                value={bwicAlert}
                alertOptions={instantAlertOptions}
                alertOptionInfo={{
                    [AlertOption.Instant]:
                        textLabels.savedFiltersPortfolioBwicInstantAlert,
                }}
                groupNameIndentifier="single-portfolio-bwicAlert-alert-state"
                title="BWIC Alerts"
                description={
                    textLabels.savedFiltersPortfolioBwicAlertDescription
                }
                onChange={onChangeBwicAlert}
                partially={bwicState === AlertEnabledState.PartiallyEnabled}
            />
            <EmailPreferencesRadioGroup
                value={inventoryAlert}
                alertOptions={instantAlertOptions}
                alertOptionInfo={{
                    [AlertOption.Instant]:
                        textLabels.saveFiltersPortfolioInventoryInstantsAlert,
                }}
                groupNameIndentifier="single-portfolio-inventoryAlert-alert-state"
                title="Inventory Alerts"
                description={
                    textLabels.savedFiltersPortfolioInventoryAlertDescription
                }
                onChange={onChangeInventoryAlert}
                partially={
                    inventoryState === AlertEnabledState.PartiallyEnabled
                }
            />
        </>
    );
}

