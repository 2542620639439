import { Link } from 'react-router-dom';
import { routes } from '../../../../../../constants/routes';
import { isRequestNone, isRequestSuccess, isRequesting } from '../../../../../../utils';
import { Preloader } from '../../../../../common/Preloader';
import { Popup, PopupBody, PopupFooter } from '../../../../../controls';
import { EmailPreferencesTab } from '../../EmailPreferencesContent';
import { SavePortfolioAlertsButton } from './SavePortfolioAlertsButton';
import { usePageConfig } from '../../../../../common/pageConfig/usePageConfig';
import { PageConfigType } from '../../../../../../types/page-config/PageConfigType';
import { BwicFilterType } from '../../../../../../types/filters/FilterState';
import { PortfolioUserConfigFilter } from '../../../../../../types/user-config/UserConfigFilter';
import { useEffect } from 'react';
import { useAppSelector } from '../../../../../../effects/useAppSelector';
import { useDispatch } from 'react-redux';
import { manageEmailPreferencesActions } from '../../../../../../actions/manage-email-preferences.actions';
import { AlertOption } from '../../../../../../types/email-preferences/EmailPreferences';
import { emailPreferencesUtils } from '../../../../../../utils/email-preferences.utils';
import { AlertEnabledState } from '../../../../../../types/enums/AlertEnabledState';
import { StatusMessageSection } from '../../../../../status-message/StatusMessageSection';
import { StatusMessageSectionType } from '../../../../../../types/state/NotificationState';
import { PortfolioAlertsTabs } from './PortfolioAlertsTabs';

interface Props {
    onClose: () => void;
}

export const PortfolioAlertsPopup = ({ onClose }: Props) => {
    const dispatch = useDispatch();

    const { config, requestState, updateRequestState } = usePageConfig(PageConfigType.Portfolio, true, BwicFilterType.Portfolio);
    const requestStateGetPreferences = useAppSelector(s => s.manageEmailPreferences.requestStateGetPreferences);
    const savePreferencesRequestState = useAppSelector(s => s.manageEmailPreferences.requestStateSaveEditPreferences);
    const portfolioIssuanceMonitorAlertState = useAppSelector(
        s => s.manageEmailPreferences.editPreferences.portfolioIssuanceMonitorAlertState,
    );
    const portfolioOutRiAlertState = useAppSelector(
        s => s.manageEmailPreferences.editPreferences.portfolioOutOfRiPeriodAlertState,
    );
    const portfolioOutNcAlertState = useAppSelector(
        s => s.manageEmailPreferences.editPreferences.portfolioOutOfNcPeriodAlertState,
    );
    const portfolioRollerDeadlineAlertState = useAppSelector(
        s => s.manageEmailPreferences.editPreferences.portfolioRollerDeadlineAlertState,
    );
    const allSecuritiesBwicAlertState = useAppSelector(
        s => s.manageEmailPreferences.editPreferences.portfolioBwicAlertState,
    );
    const allSecuritiesInventortAlertState = useAppSelector(
        s => s.manageEmailPreferences.editPreferences.portfolioDealersInventoryAlertState,
    );

    const allSecuritiesBwicAlertOption = emailPreferencesUtils.convertAlertEnabledStateToAlertOption(
        allSecuritiesBwicAlertState,
        AlertOption.Instant,
    );
    const allSecuritiesInventoryAlertOption = emailPreferencesUtils.convertAlertEnabledStateToAlertOption(
        allSecuritiesInventortAlertState,
        AlertOption.Instant,
    );
    const issuanceMonitorAlertOption = emailPreferencesUtils.convertAlertEnabledStateToAlertOption(
        portfolioIssuanceMonitorAlertState,
        AlertOption.Instant,
    );
    const outOfRiAlertOption = emailPreferencesUtils.convertAlertEnabledStateToAlertOption(
        portfolioOutRiAlertState,
        AlertOption.Periodic,
    );
    const outOfNcAlertOption = emailPreferencesUtils.convertAlertEnabledStateToAlertOption(
        portfolioOutNcAlertState,
        AlertOption.Periodic,
    );
    const rollerDeadlineAlertOption = emailPreferencesUtils.convertAlertEnabledStateToAlertOption(
        portfolioRollerDeadlineAlertState,
        AlertOption.Instant,
    );

    useEffect(() => {
        if(config?.filters) {
            dispatch(manageEmailPreferencesActions.initPortfolioFilterAlerts(config.filters))
        }
    }, [dispatch, config?.filters])

    useEffect(() => {
        if (isRequestSuccess(savePreferencesRequestState)) {
            onClose();
        } // eslint-disable-next-line
    }, [savePreferencesRequestState]);

    useEffect(
        () => () => {
            dispatch(manageEmailPreferencesActions.reset());
        },
        [dispatch],
    );

    useEffect(() => {
        if (isRequestNone(requestStateGetPreferences)) {
            dispatch(manageEmailPreferencesActions.getInitialDataRequest());
        }
    }, [dispatch, requestStateGetPreferences]);

    const handleChangeImAlert = (value: AlertOption) => {
        dispatch(
            manageEmailPreferencesActions.setPortfolioIssuanceMonitorAlerts(
                emailPreferencesUtils.convertAlertOptionToAlertEnabledState(value),
            ),
        );
    };

    const handleChangeOutOfRiAlert = (value: AlertOption) => {
        dispatch(
            manageEmailPreferencesActions.setPortfolioRiAlerts(
                emailPreferencesUtils.convertAlertOptionToAlertEnabledState(value),
            ),
        );
    };

    const handleChangeOutOfNcAlert = (value: AlertOption) => {
        dispatch(
            manageEmailPreferencesActions.setPortfolioNcAlerts(
                emailPreferencesUtils.convertAlertOptionToAlertEnabledState(value),
            ),
        );
    };

    const handleChangeRollerDeadlineAlert = (value: AlertOption) => {
        dispatch(
            manageEmailPreferencesActions.setPortfolioRollerDeadlineAlerts(
                emailPreferencesUtils.convertAlertOptionToAlertEnabledState(value),
            ),
        );
    };

    const handleBwicAlertChange = (value: AlertOption) => {
        dispatch(
            manageEmailPreferencesActions.setPortfolioBwicAlerts(
                emailPreferencesUtils.convertAlertOptionToAlertEnabledState(value),
            ),
        );
    };

    const handleInventoryAlertChange = (value: AlertOption) => {
        dispatch(
            manageEmailPreferencesActions.setPortfolioInventoryAlerts(
                emailPreferencesUtils.convertAlertOptionToAlertEnabledState(value),
            ),
        );
    };

    return (
        <Popup
            renderInBody
            title="Portfolio Alerts"
            modalClass="modal-email-preferences email-preferences-fixed-height modal-portfolio-alerts"
            onClose={onClose}
        >
            <PopupBody>
                <Preloader inProgress={!isRequestSuccess(requestState, requestStateGetPreferences)}>
                    <div className="page-content-profile">
                        {(portfolioIssuanceMonitorAlertState === AlertEnabledState.PartiallyEnabled ||
                            portfolioRollerDeadlineAlertState === AlertEnabledState.PartiallyEnabled ||
                            allSecuritiesBwicAlertState === AlertEnabledState.PartiallyEnabled ||
                            allSecuritiesInventortAlertState === AlertEnabledState.PartiallyEnabled) && (
                            <StatusMessageSection type={StatusMessageSectionType.Info}>
                                Some alerts are disabled on partial check, see Portfolio page for details and management.
                            </StatusMessageSection>
                        )}
                        <div className="profile-content">
                            <div className="notifications-center">
                                <PortfolioAlertsTabs
                                    bwicAlert={allSecuritiesBwicAlertOption}
                                    bwicState={allSecuritiesBwicAlertState}
                                    onChangeBwicAlert={handleBwicAlertChange}
                                    inventoryAlert={allSecuritiesInventoryAlertOption}
                                    inventoryState={allSecuritiesInventortAlertState}
                                    onChangeInventoryAlert={handleInventoryAlertChange}
                                    imAlert={issuanceMonitorAlertOption}
                                    imState={portfolioIssuanceMonitorAlertState}
                                    onChangeImAlert={handleChangeImAlert}
                                    outOfRiAlert={outOfRiAlertOption}
                                    outOfRiState={portfolioOutRiAlertState}
                                    onChangeOutOfRiAlert={handleChangeOutOfRiAlert}
                                    outOfNcAlert={outOfNcAlertOption}
                                    outOfNcState={portfolioOutNcAlertState}
                                    onChangeOutOfNcAlert={handleChangeOutOfNcAlert}
                                    rollerDeadlineAlert={rollerDeadlineAlertOption}
                                    rollerDeadlineState={portfolioRollerDeadlineAlertState}
                                    onChangeRollerDeadlineAlert={handleChangeRollerDeadlineAlert}
                                    portfolioFilters={(config?.filters as PortfolioUserConfigFilter[]) ?? []}
                                    isFilterTableDisabled={isRequesting(
                                        requestState,
                                        updateRequestState,
                                        savePreferencesRequestState,
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </Preloader>
            </PopupBody>
            <PopupFooter>
                <Link
                    to={routes.profileNotificationsTab(EmailPreferencesTab.Portfolio)}
                    className="btn-link margin-r-a"
                >
                    Manage Alerts
                </Link>
                <button className="btn btn-ghost" onClick={onClose}>
                    Cancel
                </button>
                <SavePortfolioAlertsButton />
            </PopupFooter>
        </Popup>
    );
};
